import "./App.css";
import Logo from "./assets/logo.jpg";
import Background from "./assets/DSC08954.JPG";
import whyAs1 from "./assets/DSC09000.JPG";
import whyAs2 from "./assets/DSC08996.JPG";
import whyAs3 from "./assets/DSC09004.JPG";
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
// import L from "leaflet";

function App() {
  // const position: [number, number] = [36.3584, 43.1459]; // Latitude and Longitude

  return (
    <>
      {/* <div className="min-h-screen bg-gray-100">
        <header className="bg-[#2C4352] text-white shadow-md">
          <div className="container mx-auto">
            <img src={Logo} className="w-32 h-32" alt="شعار" />
          </div>
        </header>
        <main>
          <section
            className="relative bg-cover bg-center text-white py-80"
            style={{
              backgroundImage: `url(${Background})`,
            }}>
            <div
              className="absolute inset-0 bg-black opacity-70"
              style={{ zIndex: 1 }}></div>

            <div
              className="relative container mx-auto px-4 w-full flex justify-center items-center flex-col text-center"
              style={{ zIndex: 2 }}>
              <h2 className="text-8xl font-bold mb-4">imagin 3D</h2>
              <p className="text-3xl">حيث يتحول الخيال إلى واقع!</p>
            </div>
          </section>

          <section className="bg-[#336699] py-36">
            <div className="container mx-auto px-4">
              <div className="bg-white rounded-lg shadow-lg p-16">
                <h3 className="text-3xl font-bold mb-4 flex justify-center items-center">
                  عن ايماجن <p className="mr-2">3D</p>
                </h3>

                <p className="flex justify-center items-center text-xl text-center">
                  في طليعة تكنولوجيا الطباعة ثلاثية الأبعاد، حيث نقدم حلولاً
                  مبتكرة للأعمال التجارية والأفراد على حد سواء. توفر طابعاتنا
                  المتطورة وفريقنا الخبراء تحويل أفكارك إلى واقع بدقة وإبداع
                </p>
              </div>
            </div>
          </section>
          <section className="bg-[#336699] py-20">
            <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white rounded-lg shadow-lg text-center">
                <img
                  src={whyAs1} // Adjust path as needed
                  alt="تصاميم مبتكرة"
                  className="inline-block rounded-t-lg mb-4 max-w-full h-auto object-cover"
                />
                <h4 className="text-xl font-bold my-3">تصاميم مبتكرة</h4>
                <p className="p-3">
                  حول أفكارك الأكثر جرأة إلى واقع باستخدام تقنيتنا المتطورة
                  للطباعة ثلاثية الأبعاد.
                </p>
              </div>
              <div className="bg-white rounded-lg shadow-lg text-center pb-8">
                <img
                  src={whyAs2} // Adjust path as needed
                  alt="نموذج أولي سريع"
                  className="inline-block rounded-t-lg mb-4 max-w-full h-auto object-cover"
                />
                <h4 className="text-xl font-bold my-3">نموذج أولي سريع</h4>
                <p className="p-3">
                  حول مفاهيمك إلى واقع في وقت قياسي مع خدمتنا السريعة.
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-lg text-center pb-8">
                <img
                  src={whyAs3} // Adjust path as needed
                  alt="نموذج أولي سريع"
                  className="inline-block rounded-t-lg mb-4 max-w-full h-auto object-cover"
                />
                <h4 className="text-xl font-bold my-3">حلول مخصصة</h4>
                <p className="p-3">
                  لا يوجد مشروع كبير أو صغير. نحن نخصص خدماتنا لتلبية احتياجاتك
                  الفريدة.
                </p>
              </div>
            </div>
          </section>

          <section className="bg-[#1A2B3C] py-6">
            <div className="flex flex-col sm:flex-row  items-center justify-center space-x-4">
              <a
                href="https://www.instagram.com/imagin3d.iq" // Replace with your Instagram profile link
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 hover:text-gray-900">
                <FaInstagram size={60} />
              </a>
            </div>
          </section>
        </main>
        <footer className="bg-[#1A2B3C] text-white py-4">
          <div className="container mx-auto px-4 flex justify-center items-center text-center">
            <p>© 2024 جميع الحقوق محفوظة. دعنا نطبع أحلامك!</p>
          </div>
        </footer>
      </div> */}
      <div className="flex flex-col w-full min-h-screen bg-white">
        <div className="flex flex-col w-full min-h-screen bg-white">
          <header className="bg-[#2c4154] text-white">
            <nav className="flex justify-between items-center">
              <div className="text-sm">
                <img src={Logo} className="w-auto h-28" alt="" />
              </div>
            </nav>
          </header>

          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 mb-12 h-[600px]">
              <div>
                <h1 className="text-center sm:text-start p-10 sm:p-0 text-4xl font-bold mb-4 h-full flex justify-center items-center">
                  في طليعة تكنولوجيا الطباعة ثلاثية الأبعاد
                </h1>
              </div>
              <div className="flex justify-start sm:justify-center items-center flex-col">
                <h2 className="flex justify-center items-center sm:block text-xl font-semibold mb-2 w-full">
                  نقدم حلولاً مبتكرة للأعمال التجارية
                </h2>
                <p className="flex justify-center items-center sm:block text-sm text-gray-600 sm:pl-40 pl-0 sm:px-0 px-10 sm:text-start text-center">
                  توفر طابعاتنا المتطورة وفريقنا الخبراء تحويل أفكارك إلى واقع
                  بدقة وإبداع. نسعى جاهدين لتحقيق التميز والابتكار في كل مشروع
                  نقوم به، مما يتيح لك الوصول إلى منتجات عالية الجودة وفريدة.
                </p>
              </div>
            </div>

            {/* First image background */}
            <div className="h-[600px]">
              <div
                className="relative bg-cover bg-center text-white p-8 mb-12 h-full"
                style={{
                  backgroundImage: `url(${whyAs1})`,
                  backgroundAttachment: "fixed",
                }}>
                <h2 className="text-3xl flex text-center justify-center items-center h-full font-bold mb-2">
                  للأفراد على حد سواء مع
                  <br /> خدمات طباعة مخصصة
                </h2>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 h-[600px]">
              <div className="sm:flex hidden justify-start sm:justify-center items-center flex-col">
                <h2 className="flex justify-center items-center sm:block text-xl font-semibold mb-2 w-full sm:pr-40 pr-0">
                  خدمة مخصصة تناسب احتياجاتك
                </h2>
                <p className="flex justify-center items-center sm:block text-sm text-gray-600 sm:pr-40 sm:px-0 px-10 sm:text-start text-center">
                  من الطباعة ثلاثية الأبعاد إلى التصاميم الفريدة، فريقنا المتخصص
                  مستعد لتلبية جميع احتياجاتك. نعمل معك على تحويل أفكارك إلى
                  نماذج واقعية بكل تفاصيلها الدقيقة.
                </p>
              </div>
              <div>
                <h1 className="text-center sm:text-start p-10 sm:p-0 text-4xl font-bold mb-4 h-full flex justify-center items-center">
                  تحويل أفكارك إلى واقع بدقة وإبداع
                </h1>
              </div>
              <div className="sm:hidden flex justify-start sm:justify-center items-center flex-col">
                <h2 className="flex justify-center items-center sm:block text-xl font-semibold mb-2 w-full sm:pr-40 pr-0">
                  خدمة مخصصة تناسب احتياجاتك
                </h2>
                <p className="flex justify-center items-center sm:block text-sm text-gray-600 sm:pr-40 sm:px-0 px-10 sm:text-start text-center">
                  من الطباعة ثلاثية الأبعاد إلى التصاميم الفريدة، فريقنا المتخصص
                  مستعد لتلبية جميع احتياجاتك. نعمل معك على تحويل أفكارك إلى
                  نماذج واقعية بكل تفاصيلها الدقيقة.
                </p>
              </div>
            </div>

            <div
              className="relative bg-cover bg-center text-white p-8 mb-12 h-[600px]"
              style={{
                backgroundImage: `url(${whyAs3})`,
                backgroundAttachment: "fixed",
              }}>
              <h2 className="text-3xl flex text-center justify-center items-center h-full font-bold mb-2">
                حلول تقنية متطورة <br />
                تناسب جميع المجالات
              </h2>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mb-12 h-[600px]">
              <div>
                <h1 className="text-center sm:text-start p-10 sm:p-0 text-4xl font-bold mb-4 h-full flex justify-center items-center">
                  تصاميم فريدة تناسب احتياجات العملاء
                </h1>
                {/* Updated h2 with different text */}
              </div>
              <div className="flex justify-start sm:justify-center items-center flex-col">
                <h2 className="flex justify-center items-center sm:block text-xl font-semibold mb-2 w-full">
                  الابتكار والتميز في كل مشروع نقوم به
                </h2>
                <p className="flex justify-center items-center sm:block text-sm text-gray-600 sm:pl-40 pl-0 sm:px-0 px-10 sm:text-start text-center">
                  نوفر مجموعة متنوعة من الخدمات المتخصصة في مجال الطباعة ثلاثية
                  الأبعاد، مصممة لتلبية احتياجاتك الفردية أو التجارية. حلولنا
                  الإبداعية تضمن نتائج مذهلة في كل مرة.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-[#1A2B3C] py-6">
          <div className="flex flex-col sm:flex-row  items-center justify-center space-x-4">
            <a
              href="https://www.instagram.com/imagin3d.iq" // Replace with your Instagram profile link
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900">
              <FaInstagram size={60} />
            </a>
          </div>
        </section>
        <footer className="bg-[#1A2B3C] text-white py-4">
          <div className="container mx-auto px-4 flex justify-center items-center text-center">
            <p>© 2024 جميع الحقوق محفوظة. دعنا نطبع أحلامك!</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
